import request from '@/utils/request'

// 轮播图 分页
export function pagebanner (query) {
  return request({
    url: '/wisdom/wisdom-banner/page',
    method: 'get',
    params: query
  })
}

// 轮播图 详情
export function getbanner (query) {
  return request({
    url: '/wisdom/wisdom-banner/detail',
    method: 'get',
    params: query
  })
}

// 轮播图 新增
export function addbanner (data) {
  return request({
    url: '/wisdom/wisdom-banner/add',
    method: 'post',
    data
  })
}

// 轮播图 修改
export function editbanner (data) {
  return request({
    url: '/wisdom/wisdom-banner/edit',
    method: 'post',
    data
  })
}

// 轮播图 删除
export function delbanner (data) {
  return request({
    url: '/wisdom/wisdom-banner/delete',
    method: 'post',
    data
  })
}

// 广告所属 分页
export function pagebannerGenus (query) {
  return request({
    url: '/wisdom/wisdom-banner-genus/page',
    method: 'get',
    params: query
  })
}

// 广告所属 列表
export function listbannerGenus (query) {
  return request({
    url: '/wisdom/wisdom-banner-genus/list',
    method: 'get',
    params: query
  })
}

// 广告所属 详情
export function getbannerGenus (query) {
  return request({
    url: '/wisdom/wisdom-banner-genus/detail',
    method: 'get',
    params: query
  })
}

// 广告所属 新增
export function addbannerGenus (data) {
  return request({
    url: '/wisdom/wisdom-banner-genus/add',
    method: 'post',
    data
  })
}

// 广告所属 编辑
export function editbannerGenus (data) {
  return request({
    url: '/wisdom/wisdom-banner-genus/edit',
    method: 'post',
    data
  })
}

// 广告所属 删除
export function delbannerGenus (data) {
  return request({
    url: '/wisdom/wisdom-banner-genus/delete',
    method: 'post',
    data
  })
}
